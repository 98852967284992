.work-order{
  background-color: white;
  min-width: 750px;
  padding: 0px 25px;
  margin-top: 15px;
  overflow: hidden;
}
.work-order .top{
  display: flex;
  line-height: 64px;
  justify-content: space-between;
  white-space: nowrap;
  border-radius: 2px 2px 0px 0px;
  border-bottom: 1px solid rgb(240, 240, 240);
}
.work-order .top .left .title{
  width: 64px;
  height: 24px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 24px;
}
.work-order .top .left .total{
  display: inline-block;
  font-size: 12px;
  width: 32px;
  height: 20px;
  line-height: 20px;
  border-radius: 12px;
  text-align: center;
  background-color: rgb(243, 243, 243);
}

.ant-input-affix-wrapper{
  padding: 0;
  height: 32px;
}
.work-order .top .right input{
  height: 22px;
  vertical-align: middle;
  border-radius: 2px;
}


.work-order .bottom{
  width: 100%;
  text-align: center;
}

#loadmore-btn{
  width: 88px;
  height: 32px;
  margin-top: 17px;
  border-radius: 2px;
  margin-bottom: 24px;
}

/* -------------- Modal ---------------- */

.ant-row.ant-form-item{
  margin-bottom: 10px;
}
.uploadFile{
  position: relative;
  width: 150px;
  height: 32px;
  bottom: 27px;
  right: 16px;
  opacity: 0;
}