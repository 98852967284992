.login {
  position: relative;
  background: url('@/assets/images/login_bg.jpg') no-repeat fixed center;
  background-size: cover;
  height: 100vh;
}
.login .page-login {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 360px;
  padding: 20px;
  padding-bottom: 0;
  border-radius: 10px;
  background: linear-gradient(230deg, rgba(53, 57, 74, 0), #222);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.7);
}
.login .page-login__title {
  font-size: 22px;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}
.login .page-login .form-item__label {
  width: 50px;
  text-align: justify;
  text-align-last: justify;
  color: #fff;
}
.login .page-login .ant-input-affix-wrapper {
  padding: 4px 11px;
}
