.invoice-main{
  background-color: white;
  margin-top: 10px;
  overflow: hidden;
}
.invoice-main-top{
  display: flex;
  line-height: 64px;
  justify-content: space-between;
  white-space: nowrap;
  border-radius: 2px 2px 0px 0px;
}
.invoice-main-top .left .title{
  width: 64px;
  height: 24px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  margin-left: 24px;
  line-height: 24px;
}

.invoice-main-content button{
  border-radius: 2px;
}
.invoice-main-pagination{
  width: 100%;
  margin: 10px 0;
  text-align: center;
}
