.work-desc-page-title{
  margin: 12px 0 0 0;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 15px;
  height: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
}
.detail{
  min-width: 920px;
  width: 80%;
  margin: 0 auto;
}

.card-desc{
  background-color: white;
  height: 60px;
  line-height: 60px;
  font-size: 13px;
  box-shadow: 1px 2px 5px #0f0f0f3f;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow: hidden;
}
.card-desc-content{
  display: flex;
  justify-content: space-between;
}
.card-desc-content-item{
  width: 550px;
  display: flex;
  margin-left: 20px;
  margin-right: 15px;
  justify-content: space-between;
}
.card-desc-item-title{
  color: rgb(194, 194, 194);
}
.card-desc-item-content{
  font-weight: bolder;
  color: rgba(10, 10, 10, 0.795);
}

.detial-main{
  background-color: white;
  margin-top: 20px;
  box-shadow: 1px 2px 5px #0f0f0f3f;
  /* padding: 15px 15px ; */

}
.main-theme{
  padding-bottom: 10px;
}
.top-desc p{
  line-height: 25px;
  font-size: 15px;
}

.main-theme{
  padding: 15px 15px;
}
.main-comment{
  font-size: 14px;
  position: relative;
  margin-top: 10px;
  padding: 15px 15px;
}
#main-reply{
  padding-top: 10px;
  white-space: normal;
  position: relative;
  border-radius: 2px;
  border-top: 1px solid rgb(194, 194, 194);
  padding: 15px 15px;
}
#text-area{
  position: relative;
  z-index: 0;
}
.reply-options{
  display: flex;
  justify-content: space-between;
}


/* antd mod */
.ant-comment-actions{
  padding-right: 10px;
  text-align: right;
}
.ant-popover-message-title{
  padding: 0;
}

.ant-btn.ant-btn-sm{
  font-size: 12px;
}
.ant-btn.ant-btn-primary.ant-btn-sm{
  font-size: 14px;
}
/* wangEditor */
.w-e-text-container:hover {
  box-shadow: 0px 0px 3px rgb(41, 151, 255) inset ;
  /* border: 2px solid rgb(2, 124, 238); */
}