.detail .breadcrumb{
  font-size: 12px;
}
.detail .page-title{
  margin: 12px 0 0 0;
  font-size: 20px;
  width: 240px;
  line-height: 28px;
  height: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
.detail .content{
  padding:20px 25px 20px 25px;
  min-width: 780px;
  white-space: nowrap;
  margin-top: 16px;
  background-color: white;
  border-radius: 2px;
}
.detail .content .top-desc{
  position: relative;
  border-radius: 2px;
}
.dev-top-desc-btn{
  width: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  bottom: 10px;
}
.dev-top-desc-btn button{
  border: 2px;
}
.main{
  margin-top: 10px;
}
.main-pagination{
  width: 100%;
  margin-top: 10px;
  text-align: center;
}




/* antd mod */
.ant-comment-actions{
  padding-right: 10px;
  text-align: right;
}
.ant-popover-message-title{
  padding: 0;
}

.ant-btn.ant-btn-sm{
  font-size: 12px;
}
.ant-btn.ant-btn-primary.ant-btn-sm{
  font-size: 14px;
}

.ant-col.ant-col-16.ant-form-item-control{
  flex: auto;
  max-width: none;
}