.header {
  height: 48px;
  background-color: '#1F293D';
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 0 15px;
}
.logo {
  font-size: 14px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 20px;
}
.user {
  display: flex;
  align-items: center;
}
.user_name {
  font-size: 14px;
  font-weight: 400;
  color: #b0b4bb;
  line-height: 22px;
  margin-right: 34px;
}
.user_why {
  width: 18px;
  height: 18px;
  background: #b0b4bb;
  margin-right: 17px;
  border-radius: 50%;
  color: #272829;
  line-height: 18px;
  text-align: center;
}
.user_language {
  width: 36px;
  height: 20px;
  background: #b0b4bb;
  border-radius: 45px 49px 49px 45px;
  color: #272829;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}
