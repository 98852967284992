.users-space {
  width: 100%;
}
.table-content {
  background-color: #fff;
  border-radius: 2px;
  padding: 16px 24px 24px;
  width: 100%;
}
.ant-input-affix-wrapper {
  padding: 4px 11px;
}
.formItem .ant-input {
  width: 300.6px;
}
.formItem .ant-input-affix-wrapper {
  width: 300.6px;
}
